


























































































import Vue from "vue";
import Header from "@/components/desktop/NavBar.vue";
import ItemCardDesktop from "@/components/desktop/ItemCardDesktop.vue";
import ItemCardMobile from "@/components/mobile/ItemCardMobile.vue";
import { getUserItems, getUser, getUserDeactivatedItems } from "../api/user";
import { User, Item } from "@/types";

export default Vue.extend({
  name: "profile",
  components: {
    "header-top": Header,
    ItemCardDesktop,
    ItemCardMobile
  },
  data() {
    return {
      items: [] as Item[],
      blockedItems: [] as Item[],
      favItems: [] as Item[],
      user: {} as User
    };
  },
  computed: {
    phone() {
      let pno;
      if (this.user.phone != undefined) {
        //@ts-ignore
        pno = this.user.phone;
      }
      return pno;
    },
    name() {
      //@ts-ignore
      return this.user.firstName + " " + this.user.lastName;
    }
  },
  methods: {
    getItems() {
      const userId = this.$route.params.id;
      return getUserItems(userId)
        .then(items => {
          this.items = items;
        })
        .catch(error => console.log(error));
    },
    getUserDetails() {
      const userId = this.$route.params.id;
      getUser(userId)
        .then(user => {
          this.user = user;
        })
        .catch(error => {
          console.log(error);
          //this.$router.replace("/signin");
        });
    },
    getDeactivatedItems() {
      const userId = this.$route.params.id;
      getUserDeactivatedItems(userId)
        .then(items => {
          this.blockedItems = items;
        })
        .catch(error => {
          console.log(error);
        });
    },
    getFavoriteItems() {
      const user = this.$store.getters.getLoggedUser;
      const userId = this.$route.params.id;

      if (user._id === userId) {
        this.favItems = user.wishlist;
      } else {
        this.favItems = [];
      }
    }
  },
  created() {
    this.getUserDetails();
    this.getItems();
    this.getDeactivatedItems();
  }
});
