import { User, Item } from "../types";
import http from "../utils/axios";
import store from "../store";

export const getUserItems = (userId: string) => {
  return new Promise<Array<Item>>((resolve, reject) => {
    http
      .get("/items?user=" + userId)
      .then((response) => {
        const items: Item[] = response.data.items;
        resolve(items);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getUser = (id: string) => {
  return new Promise<User>((resolve, reject) => {
    http
      .get("/users/" + id, {
        headers: {
          token: store.getters.getToken,
        },
      })
      .then((response) => {
        const user: User = response.data.user;
        resolve(user);
      })
      .catch((error) => reject(error));
  });
};

export const getUserDeactivatedItems = (id: string) => {
  return new Promise<Item[]>((resolve, reject) => {
    getUserItems(id)
      .then((items) => {
        const filteredItems = items.filter((item) => item.blocked === true);
        resolve(filteredItems);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
